import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { MaterialModule } from './app.material.module';
import { ROUTES } from './routes';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { NavigationModule } from './components/navigation/navigation.module';
import { FooterModule } from './components/footer/footer.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { FunctieResource } from './services/resources/functie.resource';
import { StateService } from './services/state/state.service';
import { ShareModule } from './components/share/share.module';
import { Environment } from './services/environment/environment.token';
import { environment } from '../environments/environment';
import { HeaderModule } from './components/header/header.module';
import { ProfileInterceptorService } from './services/resources/profile-interceptor.service';
import { AnalyticsService } from './services/analytics/analytics.service';
import { IeWarningModule } from './components/ie-warning-modal/ie-warning-modal.module';
import { ContentModule } from './services/content/content.module';
import { AboutComponent } from './pages/about/about.component';
import { SanitizePipe } from './components/html-container/sanitize.pipe';
import { HtmlContainerComponent } from './components/html-container/html-container.component';
import { PrismicService } from './services/prismic/prismic.service';
import { SeoService } from './services/seo.service';
import { AuthGuardService } from './services/auth-guard.service';
import {MatDialogRef} from "@angular/material/dialog";
import { JwtHelperService, JWT_OPTIONS } from "@auth0/angular-jwt";
import { NotFoundPageComponent } from './pages/page-not-found/not-found-page.component';
import {ButtonModule} from './components/button/button.module'

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    SanitizePipe,
    HtmlContainerComponent,
    NotFoundPageComponent
  ],
  imports: [
    BrowserModule,
    IeWarningModule,
    BrowserAnimationsModule,
    FooterModule,
    FormsModule,
    HttpClientModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MaterialModule,
    NavigationModule,
    ReactiveFormsModule,
    RouterModule.forRoot(ROUTES, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 80],
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
    ShareModule,
    HeaderModule,
    ContentModule,
    ButtonModule,
  ],
  providers: [
    PrismicService,
    AnalyticsService,
    StateService,
    FunctieResource,
    SeoService,
    CookieService,
    {
      provide: Environment,
      useValue: environment
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProfileInterceptorService,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: prismicProviderFactory,
      deps: [PrismicService],
      multi: true
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    JwtHelperService,
    {
      provide: JWT_OPTIONS,
      useValue: JWT_OPTIONS,
    },
    AuthGuardService
  ],
  exports: [
    SanitizePipe, HtmlContainerComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function prismicProviderFactory(prismic: PrismicService) {
  return () => prismic.load();
}
