<div
  class="app-component"
  [ngClass]="{
    'mobile-small-navigation': mode === 'MOBILE_SMALL',
    'mobile-medium-navigation': mode === 'MOBILE_MEDIUM',
    'mobile-large-navigation': mode === 'MOBILE_LARGE',
    'desktop-small-navigation': mode === 'DESKTOP_SMALL',
    'desktop-medium-navigation': mode === 'DESKTOP_MEDIUM',
    'desktop-large-navigation': mode === 'DESKTOP_LARGE',
    'desktop-extra-large-navigation': mode === 'DESKTOP_EXTRA_LARGE'
  }"
>
  <app-header
    [isMinimized]="isMinimized"
    [showNavigation]="showNavigation"
    (hamburgerClick)="toggleIsMinimized()"
  >
  </app-header>

  <app-navigation
    [isMinimized]="isMinimized"
    (minificationMenuEvent)="setIsMinimized()"
    [ngClass]="{
      maximized: !isMinimized,
      minimized: isMinimized,
      open: !isMinimized,
      'header-minimized':
        showNavigation || direction === 'down' || isSmallerMobile
    }"
  >
  </app-navigation>

  <div
    class="content-container"
    [ngClass]="{
      'full-width': !showNavigation,
      'content-minimized':
        showNavigation || direction === 'down' || isSmallerMobile,
      'content-maximized': !showNavigation && !isSmallerMobile,
      'bg': isHomepage
    }"
  >
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</div>
