import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UtilService } from '../../services/util/util.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnDestroy {
  public hideFooter: boolean = false;
  public isMobile: boolean = false;
  public subscriptions: Subscription[] = [];
  public isLoggedIn: boolean = false;

  public constructor(
    private router: Router,
    private utilService: UtilService,
  ) {
    this.subscriptions.push(utilService.getScreenMode$().subscribe(screenMode => {
      this.isMobile = screenMode.includes('MOBILE');
    }));

    this.subscriptions.push(
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.hideFooter = e.url.includes('/functies') || e.url.includes('/opleidingen') || e.url.includes('/test-overzicht') || e.url.endsWith('opbouw');
      }
    }))
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
