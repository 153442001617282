import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input()
  public type: string = 'submit';

  @Input()
  public mode: string = 'primary';

  @Input()
  public enabled: boolean = true;

  @Input()
  public fullWidth: boolean = true;

  @Input()
  public rounded: boolean = true;

  @Output()
  public clicked: EventEmitter<void> = new EventEmitter();
}
